export const mainSubject = [
  {
    subjectName: "语文",
    subjectId: 1,
  },
  {
    subjectName: "数学",
    subjectId: 2,
  },
  {
    subjectName: "英语",
    subjectId: 3,
  },
  {
    subjectName: "历史",
    subjectId: 4,
  },
  {
    subjectName: "物理",
    subjectId: 8,
  },
];
export const requiredSubject = [
  {
    subjectName: "语文",
    subjectId: 1,
  },
  {
    subjectName: "数学",
    subjectId: 2,
  },
  {
    subjectName: "英语",
    subjectId: 3,
  },
];
export const groupSubject = [
  {
    subjectName: "历史",
    subjectId: 4,
  },
  {
    subjectName: "地理",
    subjectId: 5,
  },
  {
    subjectName: "政治",
    subjectId: 6,
  },
  {
    subjectName: "生物",
    subjectId: 7,
  },
  {
    subjectName: "物理",
    subjectId: 8,
  },
  {
    subjectName: "化学",
    subjectId: 9,
  },
];
