<template>
  <div v-loading="loading" class="GradeRank">
    <detailsHeader hidden-back />
    <div class="filter-index">
      <div class="left">
        <div class="category">
          <div class="category-title">考生信息</div>
          <el-checkbox v-model="visibleForm.studentNo">学籍号</el-checkbox>
          <el-checkbox v-model="visibleForm.eleccomb">选考组合</el-checkbox>
        </div>
        <div class="category">
          <div class="category-title">班级信息</div>
          <el-checkbox v-model="visibleForm.classNum">行政班级</el-checkbox>
          <el-checkbox
            v-model="visibleForm.classNumTeach"
            :disabled="disbleTeachClass"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="总分榜单不适用"
              placement="top"
              :disabled="!disbleTeachClass"
            >
              <span>教学班级</span>
            </el-tooltip>
          </el-checkbox>
        </div>
        <div class="category">
          <div class="category-title">排名信息</div>
          <el-checkbox v-model="visibleForm.classNumRank"
            >行政班级排名</el-checkbox
          >
          <el-checkbox
            v-model="visibleForm.classNumTeachRank"
            :disabled="$route.query.subjectId === '0'"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="总分榜单不适用"
              placement="top"
              :disabled="$route.query.subjectId !== '0'"
            >
              <span>教学班级排名</span>
            </el-tooltip>
          </el-checkbox>
          <el-checkbox v-model="visibleForm.eleccateRank"
            >选考类别排名</el-checkbox
          >
          <el-checkbox v-model="visibleForm.eleccombRank"
            >选考组合排名</el-checkbox
          >
        </div>
        <div class="category">
          <div class="category-title">分数与等级</div>
          <el-checkbox v-model="visibleForm.scoreOri"> 原始分 </el-checkbox>
          <el-checkbox v-model="visibleForm.score" :disabled="disabledScore">
            <el-tooltip
              :disabled="!disabledScore"
              content="仅适用于再选科目"
              placement="bottom"
              effect="dark"
            >
              <span>赋 分</span>
            </el-tooltip>
          </el-checkbox>
          <el-checkbox v-model="visibleForm.level" :disabled="disabledScore">
            <el-tooltip
              :disabled="!disabledScore"
              content="仅适用于再选科目"
              placement="bottom"
              effect="dark"
            >
              <span>等 级</span>
            </el-tooltip></el-checkbox
          >

          <el-checkbox
            v-if="$route.query.subjectId != '0' && questions.length > 0"
            v-model="visibleForm.questionScore"
            >小题分
          </el-checkbox>
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="operate">
        <div class="title">年级榜 - {{ $route.query.subjectName }}</div>
        <div class="operate-box">
          <el-input
            v-model="search.input"
            style="width: 180px; margin-right: 18px"
            suffix-icon="el-icon-search"
            placeholder="姓名或考号"
            @input="inputChange"
          ></el-input>
          <el-dropdown @command="dropdownChange">
            <el-button type="primary">
              导出为Excel<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="current">当前报表</el-dropdown-item>
              <el-dropdown-item command="detail">详细报表</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <resEleccomb-list
        :res-eleccomb-list="resEleccombList"
        :res-eleccomb-id="resEleccombId"
        @change="handleClick"
      />
      <el-table
        :key="resEleccombId"
        ref="elTable"
        :data="tableData"
        border
        style="width: 100%; margin-bottom: 18px"
      >
        <template v-if="resEleccombId === 98 || resEleccombId === 99">
          <el-table-column
            v-if="visibleForm['eleccateRank']"
            prop="eleccateRank"
            width="120"
            label="选考类别排名"
            align="center"
          >
          </el-table-column>
        </template>
        <template v-else-if="resEleccombId !== 98 && resEleccombId !== 99">
          <el-table-column
            v-if="visibleForm['eleccombRank']"
            width="120"
            prop="eleccombRank"
            label="选考组合排名"
            align="center"
          >
          </el-table-column>
        </template>
        <el-table-column
          v-if="visibleForm['studentNo']"
          prop="studentNo"
          label="学籍号"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="studentExamId" label="考号" align="center">
        </el-table-column>
        <template v-if="$route.query.subjectId === '0'">
          <el-table-column prop="score" label="总分" align="center">
          </el-table-column>
        </template>
        <el-table-column
          v-if="visibleForm['classNum']"
          prop="classNum"
          width="120"
          label="行政班级"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm['classNumRank']"
          prop="classNumRank"
          width="120"
          label="行政班级排名"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm['eleccomb']"
          prop="eleccomb"
          label="选考组合"
          column-key="eleccomb"
          width="120"
          align="center"
        >
          <template #header>
            选考组合 <img src="@/assets/newExam/sizer.svg" alt=""
          /></template>
        </el-table-column>
        <template v-if="resEleccombId === 98 || resEleccombId === 99">
          <el-table-column
            v-if="visibleForm['eleccombRank']"
            width="120"
            prop="eleccombRank"
            label="选考组合排名"
            align="center"
          >
          </el-table-column>
        </template>
        <template v-else-if="resEleccombId !== 98 && resEleccombId !== 99">
          <el-table-column
            v-if="visibleForm['eleccateRank']"
            width="120"
            prop="eleccateRank"
            label="选考类别排名"
            align="center"
          >
          </el-table-column>
        </template>

        <el-table-column
          v-if="visibleForm['classNumTeach']"
          prop="classNumTeach"
          label="教学班级"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm['classNumTeachRank']"
          prop="classNumTeachRank"
          label="教学班级排名"
          width="120"
          align="center"
        >
        </el-table-column>

        <template v-for="(item, index) in chooseSubject">
          <template
            v-if="ischoosenSubject(item.subjectId) && visibleForm['scoreOri']"
          >
            <el-table-column
              :key="'chooseSubject' + index"
              :label="item.subjectName"
              align="center"
            >
              <template slot-scope="{ row }">
                <span
                  :class="
                    row.subjects[index].answerSheetUrl ? 'allowClick' : ''
                  "
                  @click="
                    answerClick(row, row.subjects[index].answerSheetUrl, index)
                  "
                  >{{ row.subjects[index].score }}</span
                >
              </template>
            </el-table-column>
          </template>
          <template
            v-if="
              !ischoosenSubject(item.subjectId) &&
              (visibleForm['scoreOri'] ||
                visibleForm['score'] ||
                visibleForm['level'])
            "
          >
            <el-table-column
              :key="'isChooseSubject' + index"
              align="center"
              :label="item.subjectName"
            >
              <template>
                <el-table-column
                  v-if="visibleForm['scoreOri']"
                  prop="scoreOri"
                  label="原始分"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <span
                      :class="
                        row.subjects[index].answerSheetUrl ? 'allowClick' : ''
                      "
                      @click="
                        answerClick(
                          row,
                          row.subjects[index].answerSheetUrl,
                          index
                        )
                      "
                    >
                      {{ row.subjects[index].scoreOri }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="visibleForm['score']"
                  prop="score"
                  label="赋分"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <span
                      :class="
                        row.subjects[index].answerSheetUrl ? 'allowClick' : ''
                      "
                      @click="
                        answerClick(
                          row,
                          row.subjects[index].answerSheetUrl,
                          index
                        )
                      "
                    >
                      {{ row.subjects[index].score }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="visibleForm['level']"
                  prop="level"
                  label="等级"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <span>
                      {{ row.subjects[index].level }}
                    </span>
                  </template>
                </el-table-column>
              </template>
            </el-table-column>
          </template>
        </template>
        <template v-if="$route.query.subjectId !== '0'">
          <el-table-column
            prop="objectiveScore"
            label="客观题"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="subjectiveScore"
            label="主观题"
            width="120"
            align="center"
          >
          </el-table-column>
          <template v-if="visibleForm['questionScore']">
            <el-table-column
              v-for="(item, index) in questions"
              :key="'questions' + index"
              :label="item.order"
              width="120"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.questions[index].score }}
              </template>
            </el-table-column>
          </template>
        </template>
        <el-table-column
          prop="category"
          label="分类"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="categoryRank"
          label="分类排名"
          width="120"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.categoryRank === 0 ? "" : row.categoryRank }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="search.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="search.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="导出详细榜单"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose"
    >
      <div class="export-box">
        <div class="export-box-title">选择更多数据项</div>
      </div>
      <div class="option">
        <div class="option-title">考生信息</div>
        <div class="checks">
          <el-checkbox
            v-model="checked"
            :true-label="1"
            :false-label="0"
            disabled
          >
            姓名
          </el-checkbox>
          <el-checkbox
            v-model="checked"
            :true-label="1"
            :false-label="0"
            disabled
          >
            考号
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expStudentNo"
            :true-label="1"
            :false-label="0"
          >
            学籍号
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expEleccomb"
            :true-label="1"
            :false-label="0"
          >
            选考组合
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">年级/班级信息</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expClassNum"
            :true-label="1"
            :false-label="0"
          >
            行政班级
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expClassNumTeach"
            :disabled="$route.query.subjectId === '0'"
            :true-label="1"
            :false-label="0"
          >
            <el-tooltip
              class="item"
              :disabled="$route.query.subjectId !== '0'"
              effect="dark"
              content="总分不适用"
              placement="top"
            >
              <span>教学班级</span>
            </el-tooltip>
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">排名信息</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expClassNumRank"
            :true-label="1"
            :false-label="0"
          >
            行政班级排名
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expClassNumTeachRank"
            :disabled="$route.query.subjectId === '0'"
            :true-label="1"
            :false-label="0"
          >
            <el-tooltip
              class="item"
              :disabled="$route.query.subjectId !== '0'"
              effect="dark"
              content="总分不适用"
              placement="top"
            >
              <span>教学班级排名</span>
            </el-tooltip>
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expEleccateRank"
            :true-label="1"
            :false-label="0"
          >
            选考类别排名
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expEleccombRank"
            :true-label="1"
            :false-label="0"
          >
            选考组合排名
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">分数与等级</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expScoreOri"
            :true-label="1"
            :false-label="0"
          >
            原始分
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expScore"
            :true-label="1"
            :false-label="0"
            :disabled="disabledExportScore"
          >
            赋分
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expLevel"
            :true-label="1"
            :false-label="0"
            :disabled="disabledExportScore"
          >
            等级
          </el-checkbox>
          <el-checkbox
            v-if="$route.query.subjectId !== '0'"
            v-model="exportForm.expQuestionScore"
            :true-label="1"
            :false-label="0"
          >
            小题分
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">其他</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expCategory"
            :true-label="1"
            :false-label="0"
          >
            分类
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expCategoryRank"
            :true-label="1"
            :false-label="0"
          >
            分类排名
          </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportDetail">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import detailsHeader from "@/components/detailsHeader.vue";
import { getObj } from "@/core/api/exam/exam";
import {
  newExamGradelist,
  exportgradelist,
} from "@/core/api/academic/onceExam";
import { getstateleccomboptionV2 } from "@/core/api/academic/common";
import { mainSubject, requiredSubject, groupSubject } from "./enum";
import resEleccombList from "./components/resEleccombList.vue";
export default {
  components: {
    detailsHeader,
    resEleccombList,
  },
  data() {
    return {
      checked: 1,
      value: true,
      examInformation: null,
      search: {
        current: 1,
        size: 20,
        input: "",
      },
      total: 0,
      resEleccombId: null,
      resEleccombList: [],
      tableData: [],
      chooseSubject: [],
      questions: [],
      visibleForm: {
        scoreOri: true,
        score: true,
        level: true,
        studentNo: false,
        eleccomb: true,
        classNum: true,
        classNumTeach: true,
        classNumRank: false,
        eleccateRank: false,
        eleccombRank: true,
        classNumTeachRank: false,
        questionScore: false,
      },
      dialogVisible: false,
      loading: false,
      resEleccombName: "",
      filterData: [],
      disbleTeachClass: false,
      disabledScore: false,
      exportForm: {
        expStudentNo: 1,
        expEleccomb: 1,
        expClassNum: 1,
        expClassNumRank: 1,
        expEleccombRank: 1,
        expTotalScore: 1,
        expScore: 1,
        expScoreOri: 1,
        expLevel: 1,
        expCategory: 1,
        expClassNumTeach: 1,
        expClassNumTeachRank: 1,
        expQuestionScore: 1,
        expCategoryRank: 1,
        expEleccateRank: 1,
      },
      disabledExportScore: false,
    };
  },
  mounted() {
    const { subjectId } = this.$route.query;
    const mainSubjectIds = mainSubject.map((item) => item.subjectId);
    if ([0].includes(Number(subjectId))) {
      this.disbleTeachClass = true;
      this.classNumTeach = false;
      this.visibleForm.classNumTeach = false;
      this.visibleForm.classNumTeachRank = false;
      this.visibleForm.classNumRank = true;
      this.visibleForm.eleccombRank = true;
    } else {
      this.visibleForm.classNumTeachRank = true;
    }
    if (mainSubjectIds.includes(Number(subjectId))) {
      // 语数外历物不支持筛选赋分和等级
      this.visibleForm.scoreOri = true;
      this.visibleForm.score = false;
      this.visibleForm.level = false;
      this.disabledScore = true;
    }
    this.disabledExportScore = ["1", "2", "3"].includes(subjectId);
    let result = ["1", "2", "3", "4", "8"].includes(subjectId) ? 0 : 1;
    this.disabledExportScore = ["1", "2", "3", "4", "8"].includes(subjectId);
    this.exportForm.expScore = result;
    this.exportForm.expLevel = result;
    this.exportForm.expClassNumTeach = subjectId > 0 ? 1 : 0;
    this.exportForm.expClassNumTeachRank = subjectId > 0 ? 1 : 0;
    this.getExamObj();
  },
  methods: {
    answerClick({ name, studentId, subjects }, answerSheetUrl, index) {
      if (answerSheetUrl) {
        sessionStorage.setItem("imgList", answerSheetUrl);
        const { href } = this.$router.resolve({
          path: "/academic/card",
          query: {
            name: name,
            studentId: studentId,
            subjectId:
              this.$route.query.subjectId == 0
                ? subjects[index].subjectId
                : this.$route.query.subjectId,
            statId: this.$route.query.statId,
          },
        });
        window.open(href, "_blank");
      } else {
        this.$message.error("无可查看答题卡");
      }
    },
    notTeachClass() {
      let result = ["0", "1", "2", "3"].includes(this.$route.query.subjectId);
      return result;
    },
    getTitleText() {
      const { subjectId } = this.$route.query;
      if (requiredSubject.includes(Number(subjectId))) {
        return "科目";
      } else if (groupSubject.includes(Number(subjectId))) {
        return "科目";
      } else {
        return "总分";
      }
    },
    ischoosenSubject(subjectId) {
      const result = mainSubject.some((item) => item.subjectId == subjectId);
      if (result) {
        return true;
      } else {
        return false;
      }
    },
    visibleCate(subjectId) {
      let id = subjectId;
      if (typeof id === "number") id = id.toString();
      let hasPhysics = this.examInformation.subjectIds.indexOf(subjectId) != -1;
      let current = ["0", "1", "2", "3", "4", "8"].includes(
        this.$route.query.subjectId
      );
      let isMustChoose =
        ["4", "8"].includes(subjectId) && id == this.$route.query.subjectId;
      if (["0", "1", "2", "3"].includes(this.$route.query.subjectId)) {
        return 1;
      } else {
        if (!isMustChoose) {
          return 0;
        } else if (hasPhysics && current) {
          return 1;
        } else {
          return 0;
        }
      }
    },

    async getGroup() {
      const { statId, subjectId } = this.$route.query;
      const res = await getstateleccomboptionV2({
        id: statId,
        subjectId: subjectId == "0" ? "" : subjectId,
        eleccateId: 0,
        hasCate: 1,
      });
      this.resEleccombList = [...res.data.data];
      if (res.data.data.length) {
        let length = res.data.data.length;
        this.resEleccombId = length >= 2 ? 0 : res.data.data[0].id;
        this.resEleccombName = length >= 2 ? "全部" : res.data.data[0].name;
        if (this.resEleccombId === 98 || this.resEleccombId === 99) {
          this.visibleForm.eleccombRank = false;
          this.visibleForm.eleccateRank = true;
        } else {
          this.visibleForm.eleccombRank = true;
          this.visibleForm.eleccateRank = false;
        }

        this.getData();
      }
    },
    inputChange() {
      this.search.current = 1;
      this.getData();
    },
    async getData() {
      this.loading = true;
      try {
        const { statId, subjectId } = this.$route.query;
        const res = await newExamGradelist({
          ...this.search,
          statId: statId,
          subjectId: subjectId,
          resEleccombId: this.resEleccombId,
        });

        this.tableData = res.data.data.records.map((item) => {
          let mainArr = mainSubject.map((it) => it.subjectId);
          let before = item.subjects.filter((it) =>
            mainArr.includes(it.subjectId)
          );
          let after = item.subjects.filter(
            (it) => !mainArr.includes(it.subjectId)
          );
          const subjects = [...before, ...after];
          return {
            ...item,
            subjects,
          };
        });
        this.total = res.data.data.total;
        if (res.data.data.records.length) {
          let itemSubjects = res.data.data.records[0].subjects;
          let mainArr = mainSubject.map((it) => it.subjectId);
          let before = itemSubjects.filter((it) =>
            mainArr.includes(it.subjectId)
          );
          let after = itemSubjects.filter(
            (it) => !mainArr.includes(it.subjectId)
          );
          this.chooseSubject = [...before, ...after];
          this.questions = res.data.data.records[0].questions;
          this.$nextTick(() => {
            this.$refs.elTable.doLayout();
          });
        }

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    async getExamObj() {
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
      this.getGroup();
    },
    handleClose() {
      this.dialogVisible = false;
    },
    async exportDetail() {
      const { statId, subjectId } = this.$route.query;
      const res = await exportgradelist({
        statId: Number(statId),
        subjectId: subjectId,
        resEleccombId: this.resEleccombId,
        ...this.exportForm,
        ...this.search,
      });
      this.dialogVisible = false;
      if (res.msg) {
        this.$message({
          type: "warning",
          message: res.msg,
          showClose: true,
        });
      } else {
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name: res.data.data.name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
      }
    },
    async dropdownChange(command) {
      if (command === "detail") {
        this.dialogVisible = true;
      } else {
        const { statId, subjectId } = this.$route.query;
        let exportParams = {};
        for (let key in this.visibleForm) {
          let newKey = "exp" + key.slice(0, 1).toUpperCase() + key.slice(1);
          exportParams[newKey] = Number(this.visibleForm[key]);
        }
        const res = await exportgradelist({
          statId: Number(statId),
          subjectId: subjectId,
          resEleccombId: this.resEleccombId,
          ...this.search,
          ...exportParams,
        });
        if (res.msg) {
          this.$message({
            type: "warning",
            message: res.msg,
            showClose: true,
          });
        } else {
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: res.data.data.url,
              name: res.data.data.name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
        }
      }
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getData();
    },
    handleClick(item) {
      if (item.id === 98 || item.id === 99) {
        this.visibleForm.eleccombRank = false;
        this.visibleForm.eleccateRank = true;
      } else {
        this.visibleForm.eleccombRank = true;
        this.visibleForm.eleccateRank = false;
      }
      this.search.current = 1;
      this.resEleccombId = item.id;
      this.resEleccombName = item.name;
      this.getData();
    },
    filterHandler(value, row, column) {
      // 表格筛选
      const property = column["property"];
      return row[property] === value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./filter.module.scss";
.filter-index {
  box-sizing: border-box;
  padding: 12px 18px;
  background-color: #fff;
  border-radius: 4px;
  margin: 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 18px;
    grid-row-gap: 18px;

    .category {
      height: 62px;
      background: #fbfcfd;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 8px 18px;
      grid-gap: 18px;

      .category-title {
        color: #0a1119;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
      }

      .el-checkbox {
        margin-bottom: 0;
        margin-right: 0;
      }

      .el-checkbox + .el-checkbox {
        margin-left: 18px;
      }
    }
  }
}

.GradeRank {
  box-sizing: border-box;
  padding-top: 18px;
  .table-content {
    box-sizing: border-box;
    padding: 18px;
    border-radius: 4px;
    background-color: #fff;
    .operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;
      .title {
        color: #2474ed;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.export-box {
  &-title {
    font-size: 14px;
    color: #0a1119;
    margin: 18px 0;
  }
}
.option {
  margin-bottom: 18px;
  border: 1px solid #ebecee;
  &-title {
    background: #f5f7fa;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #ebecee;
    height: 44px;
    box-sizing: border-box;
    line-height: 44px;
    padding: 0 18px;
  }
  .checks {
    height: 44px;
    line-height: 44px;
    box-sizing: border-box;
    padding: 0 18px;
    .el-checkbox {
      margin-bottom: 0;
      margin-right: 0;
    }
    .el-checkbox + .el-checkbox {
      margin-left: 18px;
    }
  }
}

::v-deep .el-dialog__footer {
  text-align: center !important;
}
</style>

<style scoped>
.Fade-enter,
.Fade-leave-to {
  opacity: 0;
}

.Fade-enter-to,
.Fade-leave {
  opacity: 1;
}

.Fade-enter-active,
.Fade-leave-active {
  transition: all 0.5s;
}
::v-deep .el-icon-arrow-down:before {
  content: "";
  font-size: 18px;
}
.allowClick {
  color: #2474ed;
  cursor: pointer;
}
</style>
